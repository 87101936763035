/* eslint-disable camelcase */
import { useCmsData } from 'utils/cms';

const PushMobileApp = ({ subtitle }: { subtitle: string }) => {
  const {
    generalSettings: { apps_mobile },
  } = useCmsData();

  return (
    <div>
      <p className="lg-bold text-neutral-800 text-center mb-6">{subtitle}</p>
      <div className="flex gap-8 w-fit m-auto">
        {apps_mobile.map((app) => (
          <a key={app.app_link.url} href={app.app_link.url}>
            <img alt="" src={app.app_logo.sizes.medium} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default PushMobileApp;
